export function noBignumber () {
  throw new Error('No "bignumber" implementation available')
}

export function noFraction () {
  throw new Error('No "fraction" implementation available')
}

export function noMatrix () {
  throw new Error('No "matrix" implementation available')
}

export function noIndex () {
  throw new Error('No "index" implementation available')
}

export function noSubset () {
  throw new Error('No "matrix" implementation available')
}
